<template>
  <div
    class="px-5 py-4 my-2 bg-white border rounded-md dark:bg-slate-800 border-slate-50 dark:border-slate-900"
  >
    <div class="flex flex-row items-start justify-between">
      <div class="flex flex-col w-full">
        <div
          class="my-1 flex justify-between text-base font-medium text-slate-900 dark:text-slate-100"
        >
          {{ campaignMessage.title }}
          <div
            class="rounded-md text-sm p-1 font-medium dark:text-white"
            :class="{
              'bg-green-500': campaignMessage.active,
              'bg-red-500': !campaignMessage.active,
            }"
          >
            {{
              campaignMessage.active
                ? $t('CAMPAIGN_MESSAGE.LIST.TABLE_HEADER.ACTIVE')
                : $t('CAMPAIGN_MESSAGE.LIST.TABLE_HEADER.INACTIVE')
            }}
          </div>
        </div>
        <div class="text-sm">
          {{ campaignMessage.description }}
        </div>
      </div>
      <div class="flex flex-row space-x-4 mt-2 ml-1">
        <omfy-button
          variant="link"
          icon="edit"
          color-scheme="secondary"
          size="small"
          @click="$emit('edit', campaignMessage)"
        >
          {{ $t('CAMPAIGN_MESSAGE.LIST.BUTTONS.EDIT') }}
        </omfy-button>
        <omfy-button
          variant="link"
          icon="dismiss-circle"
          size="small"
          color-scheme="secondary"
          @click="$emit('delete', campaignMessage)"
        >
          {{ $t('CAMPAIGN_MESSAGE.LIST.BUTTONS.DELETE') }}
        </omfy-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    campaignMessage: {
      type: Object,
      required: true,
    },
  },

  computed: {},
};
</script>
