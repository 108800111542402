import { frontendURL } from '../../../../helper/URLHelper';

const campaignMessages = accountId => ({
  parentNav: 'campaignMessages',
  routes: ['campaign_messages'],
  menuItems: [
    {
      icon: 'folder',
      label: 'CAMPAIGN_MESSAGES',
      key: 'campaignMessages',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/campaign_messages`),
      toStateName: 'campaign_messages',
    },
  ],
});

export default campaignMessages;
